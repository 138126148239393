'use strict';

angular.module('appApp')
  .controller('MainCtrl', function ($http, $scope, $state, socket, Modal, Auth, $rootScope) {
    $rootScope.product = null;

    $scope.vars = {
      products: [],
      count: -1,
      tableConfig: {
        options: {
          url: '/api/products/paged-list/' + Auth.getCurrentUser()._id,
          idField: '_id',
          toolbar: '#toolbar',
          search: true,
          maintainSelected: true,
          pagination: true,
          sidePagination: 'server',
          columns: [{
            field: 'prodId',
            title: 'id',
            align: 'right',
            valign: 'middle',
            sortable: true,
            formatter: function(value) {
              return value || '-';
            }
          }, {
            field: 'name',
            title: 'Prodotto',
            valign: 'middle',
            sortable: true
          }, {
            field: 'description',
            title: 'Descrizione',
            width: '60%',
            valign: 'middle',
            sortable: true
          }, {
            field: 'multilingual',
            title: 'Multilingua',
            valign: 'middle',
            sortable: true,
            formatter: function(value) {
              return value ? 'Sì' : 'No';
            }
          }, {
            field: 'actions',
            title: 'Azioni',
            align: 'center',
            valign: 'middle',
            events: {
              'click .continue': function (e, value, row) {
                $state.go('product', { user: row.user, id: row._id });
              },
              'click .edit': function (e, value, row) {
                Modal.new.product(row, product => {
                  $http.put('/api/products/' + row._id, product).then(() => {
                    $('#table').bootstrapTable("refresh");
                  });
                })();
              },

              'click .duplicate': function (e, value, row) {
                const clone = _.cloneDeep(row);
                clone.isNew = true;
                clone._id = null;
                clone.name += ' copia';
                Modal.new.product(clone, product => {
                  $scope.getNextProductId(nextId => {
                    product.prodId = nextId || 1;
                    $http.post('/api/products', product).then(() => {
                      $('#table').bootstrapTable("refresh");
                    });
                  });
                })();
              },

              'click .share': function (e, value, row) {
                const clone = _.cloneDeep(row);
                clone.isNew = true;
                clone._id = null;

                let desc = clone.description;

                if(desc) {
                  desc = desc.replace(/\[Importato da .*@.*\] /, '');
                }

                clone.description = '[Importato da ' + Auth.getCurrentUser().email + '] ';

                if(desc) {
                  clone.description += desc;
                }

                Modal.share.product(info => {
                  if(!info.email || info.email == Auth.getCurrentUser().email) {
                    alert('Error: inserire un utente valido.');
                    return;
                  }

                  $http.get('/api/users/userByEmail/' + info.email).then(res => {
                    const user = res.data;
                    clone.user = user._id;
                    $http.post('/api/products', clone).then(() => {
                      alert('Prodotto condiviso con l\'utente ' + info.email + '.');
                    }).catch(err => {
                      alert('Errore durante la condivisione. Riprovare tra poco');
                      console.log(err);
                    });
                  }).catch((err) => {
                    alert('Error: utente ' + info.email + ' non trovato.');
                    console.log(err);
                  });
                })();
              },

              'click .delete': function (e, value, row) {
                Modal.confirm.delete(() => {
                  $http.delete('/api/products/' + row._id).then(() => {
                    $('#table').bootstrapTable("refresh");
                  });
                })('questa ricetta');
              }
            },
            formatter: function() {
              return [
                '<a class="continue" href="javascript:void(0)" title="Vai al prodotto">',
                '<i class="glyphicon glyphicon-chevron-right"></i>',
                '</a>  ',
                '<a class="edit" href="javascript:void(0)" title="Modifica nome e/o descrizione del prodotto">',
                '<i class="glyphicon glyphicon-pencil"></i>',
                '</a>  ',
                '<a class="duplicate" href="javascript:void(0)" title="Duplica prodotto">',
                '<i class="glyphicon glyphicon-duplicate"></i>',
                '</a>  ',
                '<a class="share" href="javascript:void(0)" title="Condividi prodotto">',
                '<i class="glyphicon glyphicon-share"></i>',
                '</a>  ',
                '<a class="delete" href="javascript:void(0)" title="Elimina il prodotto">',
                '<i class="glyphicon glyphicon-remove"></i>',
                '</a>'
              ].join('');
            }
          }]
        }
      }
    };

    $(function () {
      $('#table').on('load-success.bs.table', function (e, data) {
        $scope.vars.count = data.total;
      });
    });

    $http.get('api/v1/key/getExpiryInfoByUser/' + Auth.getCurrentUser().email).then(response => {
      $scope.vars.keyExpiryInfo = response.data;
      $scope.vars.showKeyExpiryInfo = parseInt($scope.vars.keyExpiryInfo.daysLeft) < 15;
    });

    $scope.addProduct = () => {
      Modal.new.product({}, product => {
        const userId = Auth.getCurrentUser()._id;
        product.user = userId;
        product.ingredients = [];
        product.claims = [];

        $http.get('/api/user-settings/' + userId).then((res) => {
          product.manufacturer = res.data.manufacturer;
          product.packager = res.data.packager;
          product.manufacturingPlant = res.data.manufacturingPlant;
          product.countryOfOrigin = res.data.countryOfOrigin;
        }).finally(() => {
          $scope.getNextProductId(nextId => {
            product.prodId = nextId || 1;
            $http.post('/api/products', product).then(() => {
              $('#table').bootstrapTable("refresh");
            });
          });
        });
      })();
    };

    $scope.getNextProductId = (cb) => {
      if($scope.vars.count < 1) {
        cb(1);
        return;
      }

      return $http.get('/api/products/get-last/' + Auth.getCurrentUser()._id).then((res) => {
        cb(res.data.prodId + 1);
      }).catch(err => {
        cb(1);
      });
    };
  });
